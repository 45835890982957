import React from 'react'

export default function Cross() {
    return (
        <svg width="21" height="22" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line y1="-1" x2="22.6274" y2="-1" transform="matrix(-0.707107 0.707107 0.707107 0.707107 18 2)" stroke="#F4EED9" strokeWidth="2"/>
        <line x1="1.70711" y1="1.29289" x2="17.7071" y2="17.2929" stroke="#F4EED9" strokeWidth="2"/>
        </svg>
        
    )
}
