import { useMemo } from "react"


export default function useFormatYears(years) {


    const formattedYears = useMemo(() => {
        if (!years) {
            return ''
        }
        return `${years} YRS`
    }, [years])

    return formattedYears

}

