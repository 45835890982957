import React from 'react'

export default function SubTitle({ titles = [], title }) {
    return (
        <div className='scaling--banner--subtitle small-serif'>
            <div className='wrapper'>
                {title ? title :
                    <div className='inner-wrapper'>

                        {titles.map((title,index) => {
                            return (
                                <div key={index} className='text-center'>
                                    {title}
                                </div>
                            )
                        })}
                        {titles.map((title,index) => {
                            return (
                                <div key={index} className='text-center '>
                                    {title}
                                </div>
                            )
                        })}
                    </div>}
            </div>

        </div>
    )
}
