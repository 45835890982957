import React from 'react'
import Cross from '../../icons/cross'
import { GatsbyImage } from 'gatsby-plugin-image'
import Spacer from '../spacer/spacer'
import SmallSpacer from '../spacer/small-spacer'
import useFormatYears from '../../utils/use-format-years'
import Marker from '../../images/marker'
import { motion, AnimatePresence } from 'framer-motion'


export default function TeamSlideIn({ open, team, close }) {


    const years = useFormatYears(team?.years)


    return (
        <AnimatePresence exitBeforeEnter>
            {team && (<>
                <motion.button transition={{ bounce: 0, duration: 0.75 }} key='overlay' initial={{ opacity: 0, pointerEvents: 'none' }} animate={{ opacity: 0.9, pointerEvents: 'all' }} exit={{ opacity: 0, pointerEvents: 'none' }} aria-label="overlay" onClick={close} className='team-slide-overlay ' />
                <motion.div transition={{ bounce: 0, duration: 0.75 }} key={team?.name} initial={{ transform: `translateX(0%)` }} animate={{ transform: `translateX(-100%)` }} exit={{ transform: `translateX(0%)` }} data-team={team.name} className={'team-slide-in ' + (open ? 'open' : '')}>
                    <div className='team-slide-in-image re'>
                        <GatsbyImage alt='Team Image' image={team.image?.gatsbyImageData} className='h100vh-r m-h80vh' />
                        <div className='team-slide-in-close m-show'>
                            <button onClick={close} >
                                <Cross />
                            </button>
                        </div>
                    </div>
                    <div className='team-slide-in-text'>
                        <div className='team-slide-in-close  m-hide'>
                            <button onClick={close} >
                                <Cross />
                            </button>
                        </div>

                        <div className='z-1  '>
                            <div className='m-hide'>
                                <Spacer />
                                <Spacer />
                                <SmallSpacer />
                                <SmallSpacer />
                            </div>
                            <Spacer className='m-h-30' />


                            <h3 className='text-center mb20 max-500 ma'>{team.name}</h3>

                            <div className='max-325 small-serif ma orange text-center team-bio-byline '>
                                <SmallSpacer />
                                <Marker color='ff6326 ' />
                                <SmallSpacer />

                                <span className=' leather'>{team.companyPosition} {years ? `(${years})` : null}</span>
                            </div>
                            <Spacer />

                            <p className='body-copy mb40 bark  max-500 ma'>{team.bio}</p>
                            <div className='m-hide'>
                                <Spacer />
                            </div>
                        </div>

                    </div>
                </motion.div>

            </>)
            }
        </AnimatePresence >
    )
}
