import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React  from 'react'
import LeftArrowWhite from '../../icons/left-arrow-white'

import useFormatYears from '../../utils/use-format-years'




export default function TeamThumbnail({ teamMember, className, onClick = () => { } }) {



    const years = useFormatYears(teamMember.years)
    const As = (teamMember.bio ? 'button' : 'div')

    return (
        <>
            <As onClick={onClick} className={'bg-wrapper w100 pt125 re ' + className} >
                <div className={'team--member bg-image z-2   no-translate ' + (teamMember.bio ? 'parent--hover--enabled read-more ' : '')}>
                    <div className='h100'>
                        <div className='grid-column-3 flex-grow-1'>


                        </div>
                    </div>
                    <div className='flex flex-c ju-c h100'>
                        <h3 className='h4 text-center'>
                            {teamMember.name}
                        </h3>
                    </div>

                    <div className=' flex flex-end  flex-c w100 h100 '>

                        <div className='flex ju-sb w100 al-i-c team--more--wrapper h100'>
                            {teamMember.bio ? <div className='flex flex-start al-i-end h100 w100'>
                                <h6 className={'button-text  ' + (teamMember.bio ? 'reveal--on--hover' : '')}>Read More</h6>
                            </div> : <div />}
                            <div className='team--member--info'>
                                <h5 className='small-serif text-center'>{teamMember.companyPosition} {years ? `(${years})` : null}</h5>
                            </div>
                            {teamMember.bio ? <div className='flex flex-end al-i-end h100 w100'>
                                <LeftArrowWhite />
                            </div> : <div />}
                        </div>


                    </div>

                </div>
                <div className='bg-image z-1 r6'>
                    <GatsbyImage alt="" className=' h100 bg-image' image={teamMember?.image?.gatsbyImageData} objectPosition='center center' src='../../images/person.png' />
                </div>

            </As>




        </>
    )
}



export const query = graphql`
        fragment TeamThumbNail on DatoCmsTeamMember{
            ...GenericTeamMember
            bio
        }
`
