import React from 'react'

export default function LeftArrowWhite({ hoverState = true }) {
    return (
        <svg className={['arrows', (hoverState ? 'hover--true' : '')].join(' ')} width="48" height="48" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle className='circle' cx="29.4115" cy="29.5726" r="28.6918" stroke="#E0DBC7" strokeOpacity="0.2" />
            <path className='arrow' d="M27.3676 36.5646L26.6309 35.8279L32.8167 29.6559C32.8438 29.6198 32.8584 29.5759 32.8584 29.5307C32.8584 29.4856 32.8438 29.4418 32.8167 29.4057L26.7699 23.3588L27.5066 22.6221L33.5674 28.6689C33.7949 28.898 33.9226 29.2078 33.9226 29.5307C33.9226 29.8536 33.7949 30.1634 33.5674 30.3926L27.3676 36.5646Z" fill="#ff6326 " />
        </svg>

    )
}
